import React from "react";
import { MyContactForm } from "./contactForm/ContactForm";
import "./Home.css";

const WelcomeMessage = () => {
  return (
    <div>
      <h1 className="fs-1 fw-bold font-monospace text-white">
        Welcome to Our Website
      </h1>
      <p className="fs-3 text-white-50">
        We are glad to have you here.
        <br /> Please feel free to contact us with any questions.
      </p>
    </div>
  );
};

export function Home() {
  return (
    <div className="home-container">
      <div className="mx-5 d-flex justify-content-around align-items-center">
        <div>
          <WelcomeMessage />
        </div>
        <div>
          <MyContactForm />
        </div>
      </div>
    </div>
  );
}
