import React from "react";
import vanImage from "../../asset/van.jpg";

export function AboutUs() {
  return (
    <div className="mt-3" id="aboutUs">
      <h1 className="fs-1 fw-b fw-bold font-monospace ">Über uns</h1>
      <div className="mx-5 mt-5 pt-5 d-flex justify-content-around align-items-center">
        <p className="fs-3">
          Wir sind Ihr zuverlässiger Partner für Umzugsservices, Transport,
          Entrümpelung und Reinigung. Wir sorgen dafür, dass Ihr Umzug stressfrei, effizient und pünktlich abläuft.
          Unser engagiertes Team steht Ihnen in allen Phasen des Umzugs zur
          Seite – vom sicheren Transport Ihrer Möbel bis hin zur gründlichen
          Endreinigung. Wir legen Wert auf Sorgfalt, Professionalität und
          höchste Kundenzufriedenheit. Lassen Sie uns gemeinsam Ihren Umzug
          einfach und unkompliziert gestalten!
        </p>
        <img className="responsive-img" src={vanImage} alt="Van Image" />
      </div>
    </div>
  );
}
