import React from "react";
import vanImage from "../../asset/van.jpg";

export function Transportservice() {
  return (
    <div id="clearing-out" className="mt-3">
      <h1 className="fs-1 fw-b fw-bold font-monospace ">Transportservice</h1>
      <div className="mx-5 mt-5 pt-5 d-flex justify-content-around align-items-center">
        <p className="fs-3">
          Wir bieten zuverlässige und pünktliche Transportlösungen für
          Unternehmen jeder Größe. Mit modernem Fuhrpark und erfahrenen Fahrern
          sorgen wir dafür, dass Ihre Waren sicher und termingerecht ans Ziel
          gelangen. Flexibilität und maßgeschneiderte Services sind unser
          Markenzeichen – von regelmäßigen Lieferungen bis hin zu Eilaufträgen.
          Vertrauen Sie uns Ihre Logistik an!
        </p>
        <img className="responsive-img" src={vanImage} alt="Van Image" />
      </div>
    </div>
  );
}
