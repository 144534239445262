import React from "react";
import "./Navbar.css";

export function Navbar(props) {
  return (
    <nav className="custom-nav-bar navbar navbar-expand-lg justify-content-center fixed-top d-flex">
      <a className="navbar-brand" href="#">
        <img
          src="https://cdn-icons-png.flaticon.com/512/4950/4950815.png"
          width="30"
          height="30"
          className="d-inline-block align-top mx-2"
          alt=""
        />
        <span className="fw-bold">Putzfee & Umzug KG</span>
      </a>
      <div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse" // Bootstrap 5 uses 'data-bs-toggle'
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <a className="nav-item nav-link active" href="#">
              | Startseite |
            </a>
            <a className="nav-item nav-link" href="#moving">
              | Umzug |
            </a>

            <a className="nav-item nav-link" href="#clearing-out">
              | Entrümpelung |
            </a>
            <a className="nav-item nav-link" href="#clean">
              | Reinigung |
            </a>
            <a className="nav-item nav-link" href="#aboutUs">
              | Über Uns |
            </a>
            <a className="nav-item nav-link" href="#contact">
              | Kontakt |
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
