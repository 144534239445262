import React, { useRef } from "react";
import emailjs from "emailjs-com";

export function MyContactForm() {
  const form = useRef(null); // Ref for form submission
  const firstNameRef = useRef("");
  const lastNameRef = useRef("");
  const companyRef = useRef("");
  const emailRef = useRef("");
  const streetRef = useRef("");
  const cityRef = useRef("");
  const countryRef = useRef("");
  const zipCodeRef = useRef("");
  const phoneRef = useRef("");
  const messageRef = useRef("");
  const subjectRef = useRef("");


  // Send the form data via emailjs
  const sendEmail = (e) => {
    e.preventDefault();
  
    
    emailjs.init("ETOsZjath7hrHx4Q2");

    emailjs.sendForm("service_fsaf5ea", "template_xpo5ogq", form.current).then(
      (result) => {
        console.log(result.text);
        alert("Message sent successfully!");
      },
      (error) => {
        console.log(error.text);
        alert("Failed to send the message, please try again later.");
      }
    );
  };

  return (
    <div>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="contact-form text-white fs-5 row g-3"
      >
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label">
              Vorname
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              placeholder="Vorname"
              ref={firstNameRef}
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="lastName" className="form-label">
              Nachname
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              placeholder="Nachname"
              ref={lastNameRef}
              required
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="mb-3">
            <label htmlFor="company" className="form-label">
              Firmenname
            </label>
            <input
              type="text"
              className="form-control"
              id="company"
              name="company"
              placeholder="Firmenname"
              ref={companyRef}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Email"
              ref={emailRef}
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Telefonnummer
            </label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="Telefonnummer"
              ref={phoneRef}
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="street" className="form-label">
              Adresse
            </label>
            <input
              type="text"
              className="form-control"
              id="street"
              name="street"
              placeholder="Straße"
              ref={streetRef}
              required
            />
          </div>
        </div>

        <div className="col-md-2">
          <div className="mb-3">
            <label htmlFor="zipCode" className="form-label">
              Postleitzahl
            </label>
            <input
              type="text"
              className="form-control"
              id="zipCode"
              name="zipCode"
              placeholder="PLZ"
              ref={zipCodeRef}
              required
            />
          </div>
        </div>

        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="city" className="form-label">
              Stadt
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              name="city"
              placeholder="Stadt"
              ref={cityRef}
              required
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="mb-3">
            <label htmlFor="country" className="form-label">
              Land
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              name="country"
              placeholder="Land"
              ref={countryRef}
              required
            />
          </div>
        </div>

        <div className="col-md-4">
      <label htmlFor="inputState" className="form-label">
        Thema
      </label>
      <select id="inputState" className="form-select" ref={subjectRef} required
      name="subject">
      <option value="">Choose...</option>
        <option value="umzug">Umzug</option>
        <option value="transport">Transport</option>
        <option value="reinigung">Reinigung</option>
        
      </select>
      
    </div>
  

        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Nachricht
          </label>
          <textarea
            className="form-control"
            id="message"
            name="message"
            rows="4"
            placeholder="Your Message"
            ref={messageRef}
          ></textarea>
        </div>

        <button type="submit" className="btn btn-primary">
          Senden
        </button>
      </form>
    </div>
  );
}
