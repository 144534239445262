import React from "react";
import "./Reinigungsservice.css";
import Reinigungsmittel from "../../asset/Reinigungsmittel.png";

export function ReinigungsService() {
  return (
    <div id="clean" className="green-container pt-3">
      <h1 className="fs-1 fw-bold font-monospace">Reinigungsservice</h1>
      <div className="mx-5 my-5 pt-5 d-flex justify-content-around align-items-center">
        <img
          className="responsive-img"
          src={Reinigungsmittel}
          alt="Van Image"
        />

        <p className="fs-3">
          Wir bieten professionelle Reinigungsdienste für Unternehmen aller Art.
          Unsere Leistungen umfassen:
          <ul>
            <li>
              Büroreinigung: Saubere und gepflegte Arbeitsumgebungen, täglich
              oder wöchentlich.
            </li>
            <li>
              Fensterreinigung: Streifenfreie Sauberkeit für Ihre Glasflächen.
            </li>
            <li>
              Grundreinigung: Gründliche Reinigung für Böden, Teppiche und mehr.
            </li>
            <li>
              Hygieneservice: Regelmäßige Desinfektion und Reinigung von
              sanitären Anlagen.
            </li>
            <li>Verlassen Sie sich auf unsere Qualität und Zuverlässigkeit!</li>
          </ul>
        </p>
      </div>
    </div>
  );
}
