import logo from "./logo.png";
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import { Navbar } from "./components/navbar/Navbar";
import { Home } from "./components/home/Home";
import { AboutUs } from "./screens/aboutUs/AboutUs";
import { MovingSection } from "./screens/moving/Moving";
import { Footer } from "./screens/Footer/Footer";
import { Transportservice } from "./screens/Transport/Transport";
import { ReinigungsService } from "./screens/Reinigung/Reinigungsservice";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      {
        ////////////////////////////////////////////////////// // Meta data
        //////////////////////////////////////////////////////
      }
      <Helmet>
        <title>
          Professionelle Umzugs-, Transport- & Reinigungsdienste | Putzfee &
          Umzug
        </title>
        <meta
          name="description"
          content="Zuverlässige und erschwingliche Umzugs-, Transport- und Reinigungsdienste. Wir kümmern uns effizient und sorgfältig um all Ihre Umzugsbedürfnisse."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Professionelle Umzugs-, Transport- & Reinigungsdienste | Putzfee & Umzug KG"
        />
        <meta
          property="og:description"
          content="Suchen Sie nach vertrauenswürdigen Umzugshelfern und Reinigungsdiensten? Wir bieten erstklassige Umzugs-, Transport- und Reinigungsdienste zu wettbewerbsfähigen Preisen."
        />
        <meta property="og:url" content="https://www.putzfee-umzug.de" />
        <meta
          property="og:image"
          content="https://www.putzfee-umzug.de/static/media/van.e0b314a3c66e4f4d9d2a.jpg"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Professionelle Umzugs-, Transport- & Reinigungsdienste | Putzfee & Umzug KG"
        />
        <meta
          name="twitter:description"
          content="Schnelle, effiziente und erschwingliche Umzugs-, Transport- und Reinigungsdienste. Kontaktieren Sie uns noch heute für ein Angebot!"
        />
        <meta
          name="twitter:image"
          content="https://www.putzfee-umzug.de/static/media/van.e0b314a3c66e4f4d9d2a.jpg"
        />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.putzfee-umzug.de" />

        {/* Keywords */}
        <meta
          name="keywords"
          content="Putzen, Reinigung, Transport, Umzug, Umzugsservice, Reinigungsdienste, Logistik, günstige Umzugshelfer, professionelle Reinigung, Wohnungsreinigung, Haushaltsreinigung, Büroreinigung, Möbeltransport, Möbelmontage, Endreinigung, Umzugsreinigung, Umzugshilfe, Firmenumzug, Wohnungsumzug, Gebäudereinigung, Lagerung, Entrümpelung, Sperrmüllentsorgung, Haushaltsauflösung, Privatumzug, Geschäftsumzug, Firmenreinigung, Teppichreinigung, Fensterreinigung, Tiefenreinigung, Umzugslogistik, Transportlogistik, Kurzstreckenumzug, Langstreckenumzug, Lastwagenservice, Umzugskarton, Verpackungsservice, Umzugsplanung, Montagearbeiten, Einlagerung, Räumungsservice, Klaviertransport, Sperrguttransport"
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "MovingCompany",
        "name": "Putzfee & Umzug KG",
        "url": "https://www.putzfee-umzug.de",
        "logo": "https://www.putzfee-umzug.de/logo.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+4917663374151",
          "contactType": "Customer Service"
        },
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Am Grünland 62",
          "addressLocality": "Augsburg",
          "addressRegion": "Bayern",
          "postalCode": "86169",
          "addressCountry": "Deutschland"
        }
      }
    `}
        </script>
      </Helmet>
      {
        ////////////////////////////////////////////////////// // End Meta data
        //////////////////////////////////////////////////////
      }
      <Navbar />
      <Home />
      <AboutUs />
      <MovingSection />
      <Transportservice />
      <ReinigungsService />
      <Footer />
    </div>
  );
}

export default App;
