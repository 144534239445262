import React from "react";
import "./Moving.css";
import truckImage from "../../asset/trucks-travelling-by-road.png";

export function MovingSection() {
  return (
    <div id="moving" className="green-container  pt-3">
      <h1 className="fs-1 fw-b fw-bold font-monospace ">Umzugsservice</h1>
      <div className="mx-5 my-5 pt-5 d-flex justify-content-around align-items-center">
        <img className="responsive-img" src={truckImage} alt="Van Image" />

        <p className="fs-3">
          Wir sind Ihr zuverlässiger Partner für Umzugsservices, Transport und
          Reinigung. Mit langjähriger Erfahrung sorgen wir dafür, dass Ihr Umzug
          stressfrei, effizient und pünktlich abläuft. Unser engagiertes Team
          steht Ihnen in allen Phasen des Umzugs zur Seite – vom sicheren
          Transport Ihrer Möbel bis hin zur gründlichen Endreinigung. Wir legen
          Wert auf Sorgfalt, Professionalität und höchste Kundenzufriedenheit.
          Lassen Sie uns gemeinsam Ihren Umzug einfach und unkompliziert
          gestalten!
        </p>
      </div>
    </div>
  );
}
